import { DetectedIssue } from './IncidentInterfaces';

export interface KeyValuePair {
  key: string;
  value: any;
}

export interface ChatCompletionModel {
  metadata: ChatMetadata;
  messages: any[];
}

export interface ChatMetadata {
  chatIdentifier: string;
  chatModel: ChatModel;
  incidentDescriptions?: string;
  incidentTitle?: string;
  maxTokens: number;
  messageId: string;
  customPrompt?: string;
}

export interface ChatResponse {
  text: string;
  truncated: boolean | null;
  finishReason: string;
  feedbackIds: string[];
  exception: string;
}

export interface TextCompletionModel {
  model: TextModels;
  prompt: string;
  temperature: number;
  max_tokens: number;
}

export enum TextModels {
  Default = 'text-davinci-003',
  Test = 'gpt-35-turbo',
}

export enum APIProtocol {
  Rest = 'rest',
  WebSocket = 'websocket',
}

export enum FeedbackOptions {
  Like = 'like',
  Dislike = 'dislike',
  None = 'none',
}

export enum MessageStatus {
  Created = 0,
  Waiting = 1,
  InProgress = 2,
  Finished = 3,
  Cancelled = 4,
}

export enum MessageSource {
  User = 'user',
  System = 'system',
}

export enum MessageRenderingType {
  Text,
  Markdown,
  Code,
  Solution,
  Inference,
  Error,
}

export enum ChatModel {
  GPT3 = 'gpt3',
  GPT35 = 'gpt35',
  GPT35Turbo = 'gpt-35-turbo',
  GPT4 = 'gpt4',
}

export enum ResponseTokensSize {
  Small = 50,
  Medium = 200,
  Large = 500,
  XLarge = 800,
  XXLarge = 3000,
}

export interface ChatMessage {
  id: string;

  // message displayed to users in chat ui
  displayMessage: string;

  // message field used to for backend processing like api calls etc. Mostly same as displayMessage but
  //sometimes can be different when components decide to add some additional context in the message but dont want to display it to user.
  message: string;

  messageSource: MessageSource;
  timestamp: number;
  messageDisplayDate: string;
  renderingType: MessageRenderingType;
  userFeedback: FeedbackOptions;
  status: MessageStatus;

  /// <summary>
  /// This is used to store the feedback document ids for the message if any were used to construct the response. This is used to track the feedback for the message.
  /// </summary>
  feedbackDocumentIds?: string[];

  /// <summary>
  /// This is used to store any additional data associated with the chat message by the specific copilot. It is optional.
  /// </summary>
  data?: any;
}

export interface ChatProviderProps {
  children?: React.ReactNode;
  token: string | null;
}

export interface ChatContextProps {
  chatResponse: { [key: string]: string };
  setChatResponse: (response: { [key: string]: string }) => void;
  chatFinishReason: { [key: string]: string };
  setChatFinishReason: (response: { [key: string]: string }) => void;
  setCurrentChatId: (chatId: string) => void;
  // messageStore: any;
  // setMessageStore: (messageStore: any) => void;
  submitQuestionStreamForSignalR: (
    message: string,
    chatConfig: ChatConfig,
    additionalData?: ChatMessageAdditionalData
  ) => void;
  tsgCollectionName: string;
  setTsgCollectionName: (collectionName: string) => void;
  getChatCompletion: (
    queryModel: ChatCompletionModel,
    customPrompt?: string,
    autoAddResourceSpecificInfo?: boolean
  ) => Promise<ChatResponse>;
  getSuggestionPrompts: (
    conversationHistory: Conversation[],
    productIdentifier: string,
    resourceId?: string
  ) => Promise<SuggestedPromptsResponse>;
  createChatCompletionModel: (
    chatMessages: any[],
    messageId: string,
    chatIdentifier: string,
    chatModel?: ChatModel,
    responseSize?: ResponseTokensSize,
    additionalData?: ChatMessageAdditionalData
  ) => ChatCompletionModel;
  // cancelChatMessage: async (messageId: string) => {},
}

export interface ChatProfileSettingsProps {
  apiProtocol: APIProtocol;
  chatModel: ChatModel;
  chatContextLen: number;
  setChatContextLen?: (contextLen: number) => void;
  currentApiCallCount: number;
  setCurrentApiCallCount?: (count: number) => void;
  quotaEnforced: boolean;
  dailyMessageQuota: number;
  lastMessageForFeedback: string;
  lastMessageIdForFeedback: string;
  // messageStore: ChatMessage[];
  messageQuotaWarningThreshold: number;
  showMessageQuotaError: boolean;
  messageQuotaErrorMessage: string;
  showMessageQuotaWarning: boolean;
  messageQuotaWarningMessage: string;
  setChatResponse?: (response: string) => void;
  showChatRequestError: boolean;
  openAIChatRequestError: string;
  stopMessageGeneration: boolean;
  customInitialPrompt: string;
  autoAddResourceSpecificInfoToChatMessages: boolean;
  responseTokenSize: ResponseTokensSize;
  useDisplayMessageForChatHistory: boolean;
  openAIApiCallLimit: number;
}

export interface ChatConfig {
  apiProtocol: APIProtocol;
  chatModel: ChatModel;
  chatId: string;
  chatContextLen: number;
  setChatContextLen: any;
  customInitialPrompt?: string;
  autoAddResourceSpecificInfoToChatMessages?: boolean;
  responseTokenSize: ResponseTokensSize;
  useDisplayMessageForChatHistory?: boolean;
}
export interface ChatMessageAdditionalData {
  incidentTitle?: string;
  // incidentInfo?: IncidentInfo;
  incidentId?: string;
  incidentDescriptions?: string[];
  potentialIssuesDetected?: DetectedIssue[];
  serviceName?: string;
  // smeData?: string;
}

export interface TextCompletionModel {
  model: TextModels;
  prompt: string;
  temperature: number;
  max_tokens: number;
}

export interface CodeCompletionModel {
  model: CodeModels;
  prompt: string;
  temperature: number;
  max_tokens: number;
}

export interface DiagChatRequestBody {
  sessionId: string;
  resourceId: string;
  resourceKind: string;
  userId: string;
  message: string;
  isClearChatRequest: boolean;
}

export enum QueryResponseStatus {
  InProgress,
  Finished,
}

//Todo, add loading type
export enum MessageResponseType {
  DebugTrace,
  SystemMessage,
  LoadingMessage,
  SuggestedPrompts,
}

export interface DiagChatResponse {
  sessionId: string;
  message: ChatMessage;
  responseStatus: QueryResponseStatus;
  suggestedPrompts: string[];
  error: any;
  responseType: MessageResponseType;
}

export interface ClearChatResponse {
  sessionId: string;
  clearStatus: boolean;
}

export enum CodeModels {
  Default = 'code-davinci-002',
  Davinci = 'code-davinci-002',
  Cushman = 'code-cushman-001',
}

/*
It is very important to understand temperature before using it.
The temperature determines how greedy the generative model is.

- If the temperature is low, the probabilities to sample other but the class with the highest log probability will be small,
    and the model will probably output the most correct text, but rather boring, with small variation.

- If the temperature is high, the model can output, with rather high probability, other words than those with the highest probability.
    The generated text will be more diverse, but there is a higher possibility of grammar mistakes and generation of nonsense.
*/
export enum QueryTemperature {
  Low = 0.1,
  Medium = 0.3,
  High = 0.5,
  Hot = 0.8,
}

export interface OpenAIAPIResponse {
  id: string;
  object: string;
  created: number;
  model: string;
  choices: OpenAIResponseText[];
  usage: {
    prompt_tokens: number;
    completion_tokens: number;
    total_tokens: number;
  };
}

export interface OpenAIResponseText {
  text: string;
  index: number;
  logprobs: string;
  finish_reason: string;
}

export interface Conversation {
  role: string | number;
  content: string | null | undefined;
  references?: any[];
  suggestedPrompts?: string[];
}

export interface SuggestedPromptsResponse {
  suggestedPrompts: string[];
}

export interface UserPromptRefactorResponse {
  refactoringRequired: boolean;
  updatedQuestion: string;
}
