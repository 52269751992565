import { useContext, useEffect, useState } from 'react';
import {
  useAccount,
  useIsAuthenticated,
  useMsal,
  useMsalAuthentication,
} from '@azure/msal-react';
import {
  InteractionType,
  InteractionStatus,
  BrowserAuthError,
} from '@azure/msal-browser';
import { loginRequest } from '../authConfig';
import { useNavigate } from 'react-router';
import UserContext from '../contexts/UserContext';
import { setAppInsightUserContext } from '../utils/AppInsightService';

const useAuthGuard = () => {
  const { instance, accounts, inProgress } = useMsal();
  const account = useAccount(accounts[0] || {});
  const { login, result, error } = useMsalAuthentication(
    InteractionType.Redirect,
    loginRequest
  );
  const isAuthenticated = useIsAuthenticated();
  const navigate = useNavigate();
  const [isAuthorized, setIsAuthorized] = useState<boolean | null>(null);
  const { setToken, setUsername } = useContext(UserContext);
  const appLensBackendUrl = process.env.REACT_APP_APPLENS_BACKEND_URL!;

  // Handle MSAL authentication errors
  useEffect(() => {
    if (error) {
      console.error('MSAL Authentication Error:', error);
      if (
        error instanceof BrowserAuthError &&
        error.errorCode === 'interaction_in_progress'
      ) {
        console.warn(
          'Interaction already in progress. Skipping login attempt.'
        );
      } else {
        login();
      }
    }
  }, [error, login]);

  useEffect(() => {
    const checkAccess = async (accessToken: string) => {
      try {
        const res = await fetch(`${appLensBackendUrl}/api/skylight/ping`, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });
        if (res.status === 200) {
          setToken(accessToken);
          setIsAuthorized(true);
        } else if (res.status === 403) {
          navigate('/unauthorized');
        } else if (res.status === 401) {
          // Attempt to refresh the token if we receive a 401
          try {
            const response = await instance.acquireTokenSilent({
              ...loginRequest,
              account: accounts[0],
            });
            checkAccess(response.accessToken);
          } catch (e) {
            console.error('Token Refresh Error:', e);
            navigate('/tokeninvalid');
          }
        } else {
          navigate('/downpage');
        }
      } catch (error) {
        console.error('API Error:', error);
        navigate('/downpage');
      }
    };

    const requestAccessToken = async () => {
      if (accounts.length === 0) {
        console.warn('No accounts found. Redirecting to login.');
        if (inProgress === InteractionStatus.None) {
          instance.loginRedirect();
        }
        return;
      }

      try {
        const response = await instance.acquireTokenSilent({
          ...loginRequest,
          account: accounts[0],
        });
        checkAccess(response.accessToken);
      } catch (e) {
        console.error('Silent Token Acquisition Error:', e);
        if (inProgress === InteractionStatus.None) {
          instance.loginRedirect();
        }
      }
    };

    if (result && result.accessToken) {
      checkAccess(result.accessToken);
    } else if (isAuthenticated && accounts.length > 0) {
      if (account && account.name) {
        setUsername(account.name);
        setAppInsightUserContext(account.name);
      }
      requestAccessToken();
    } else if (!isAuthenticated) {
      console.warn('User is not authenticated. Redirecting to login.');
      if (inProgress === InteractionStatus.None) {
        instance.loginRedirect();
      }
    }
  }, [
    isAuthenticated,
    accounts,
    instance,
    result,
    navigate,
    setToken,
    setUsername,
    inProgress,
  ]);

  return isAuthorized;
};

export default useAuthGuard;
