import React from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import { AppInsightsContext } from '@microsoft/applicationinsights-react-js';
import { reactPlugin } from './utils/AppInsightService';
import { UserProvider } from './providers/UserProvider';
import { ChatProvider } from './providers/ChatProvider';
import { IncidentProvider } from './providers/IncidentProvider';
import UserContext from './contexts/UserContext';
import { loadTheme } from '@fluentui/react';
import { webLightTheme, FluentProvider } from '@fluentui/react-components';
import { CopilotProvider } from '@fluentui-copilot/react-copilot';
import { initializeIcons } from '@fluentui/react/lib/Icons';
import {
  PublicClientApplication,
  EventType,
  EventMessage,
  AuthenticationResult,
} from '@azure/msal-browser';
import { msalConfig } from './authConfig';
import { MsalProvider } from '@azure/msal-react';
import App from './App';

export const msalInstance = new PublicClientApplication(msalConfig);

initializeIcons();

msalInstance.initialize().then(() => {
  // Account selection logic is app dependent. Adjust as needed for different use cases.
  const accounts = msalInstance.getAllAccounts();
  if (accounts.length > 0) {
    msalInstance.setActiveAccount(accounts[0]);
  }

  msalInstance.addEventCallback((event: EventMessage) => {
    if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
      const payload = event.payload as AuthenticationResult;
      const account = payload.account;
      msalInstance.setActiveAccount(account);
    }
  });

  const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
  );

  const myTheme = {
    ...webLightTheme,
    defaultFontStyle: {
      fontSize: '18px',
    },
    // Other global theme settings...
  };

  loadTheme(myTheme);

  root.render(
    // <React.StrictMode>
    <MsalProvider instance={msalInstance}>
      <BrowserRouter>
        <FluentProvider theme={myTheme}>
          <CopilotProvider
            mode="sidecar" //or 'canvas'
            themeExtension={{
              colorBrandFlair1: 'red', // replace with your brand colors
              colorBrandFlair2: 'blue',
              colorBrandFlair3: 'green',
            }}
          >
            <UserProvider>
              <UserContext.Consumer>
                {({ token }) => (
                  <AppInsightsContext.Provider value={reactPlugin}>
                    <ChatProvider token={token}>
                      <IncidentProvider token={token}>
                        <App />
                      </IncidentProvider>
                    </ChatProvider>
                  </AppInsightsContext.Provider>
                )}
              </UserContext.Consumer>
            </UserProvider>
          </CopilotProvider>
        </FluentProvider>
      </BrowserRouter>
    </MsalProvider>
    // </React.StrictMode>
  );
});
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
