import React, { useContext, useState } from 'react';
import UserContext from '../contexts/UserContext';

interface UserProviderProps {
  children?: React.ReactNode;
}

const UserProvider: React.FC<UserProviderProps> = ({ children }) => {
  const [token, setToken] = useState<string | null>(null);
  const [username, setUsername] = useState('');

  return (
    <UserContext.Provider value={{ token, setToken, username, setUsername }}>
      {children}
    </UserContext.Provider>
  );
};

const useUserContext = () => {
  const context = useContext(UserContext);
  if (!context) {
    throw new Error('useUserContext must be used within a UserContext');
  }
  return context;
};

export { UserProvider, useUserContext };
