import React, { useContext, useEffect, useState } from 'react';
import { convert } from 'html-to-text';
import { encode, decode, isWithinTokenLimit } from 'gpt-tokenizer';
import {
  IncidentInfo,
  DetectedIssue,
  ValidationResults,
  OnCallDriInfo,
} from '../interfaces/IncidentInterfaces';
import {
  APIProtocol,
  ChatMessageAdditionalData,
  ChatModel,
  ResponseTokensSize,
} from '../interfaces/OpenAiInterfaces';
import { StringUtilities } from '../utils/StringUtilities';
import { defaultResourceTypes } from '../utils/shared/HomePageMenuOptions';
import { getReq, postReq } from '../api/request';
import { useChatContext } from './ChatProvider';
import IncidentContext from '../contexts/IncidentContext';
import useQuery from '../hooks/useQuery';
import useAppInsights from '../hooks/useAppInsights';
import { useNavigate } from 'react-router-dom';

interface IncidentProviderProps {
  children?: React.ReactNode;
  token: string | null;
}

// CONSTANTS
const apiProtocol = APIProtocol.Rest;
const chatIdentifier = 'tsgquestioncreator';
const chatModel = ChatModel.GPT4;
// const chatContextLength = 6;
const dailyMessageQuota = 20;
const messageQuotaWarningThreshold = 10;
const quotaEnforced = true;
const isSignalRConnectionRequired = true;
const inputTextLimit = 500;
const openAIApiCallLimit = 10;
const responseTokenSize = ResponseTokensSize.XLarge;
const appLensBackendUrl = process.env.REACT_APP_APPLENS_BACKEND_URL!;
const ChatModelTokenLimits: Record<ChatModel, number> = {
  [ChatModel.GPT3]: 4096,
  [ChatModel.GPT35]: 4096,
  [ChatModel.GPT35Turbo]: 16385,
  [ChatModel.GPT4]: 128000,
};

const IncidentProvider: React.FC<IncidentProviderProps> = ({
  children,
  token,
}) => {
  const [incidentId, setIncidentId] = useState('');
  const [incidentInfo, setIncidentInfo] = useState<IncidentInfo>(
    {} as IncidentInfo
  );
  const [
    potentialIssuesDetectedForIncident,
    setPotentialIssuesDetectedForIncident,
  ] = useState<DetectedIssue[] | undefined>(undefined);
  const [incidentDescriptions, setIncidentDescriptions] = useState<string[]>(
    []
  );
  const [tsgQuestions, setTsgQuestions] = useState<string[]>([]);
  const [smeQuestions, setSmeQuestions] = useState<string[]>([]);
  const [topIncidents, setTopIncidents] = useState<any[]>([]); //TODO: Define the type
  const [serviceName, setServiceName] = useState('');
  const [serviceType, setServiceType] = useState('');
  // const [smeData, setSmeData] = useState<string>('');
  const [incidentMetadata, setIncidentMetadata] =
    useState<ChatMessageAdditionalData>({});
  const [chatContextLen, setChatContextLen] = useState<number>(6);

  const query = useQuery();
  const incidentIdParam = query.get('incidentId') ?? '';

  const { submitQuestionStreamForSignalR, chatResponse, setCurrentChatId } =
    useChatContext();
  const { trackAppException } = useAppInsights();
  const navigate = useNavigate();

  useEffect(() => {
    if (chatResponse && chatResponse[chatIdentifier]) {
      const response = chatResponse[chatIdentifier];
      handleResponseChange(response);
    }
  }, [JSON.stringify(chatResponse[chatIdentifier])]);

  useEffect(() => {
    if (
      incidentIdParam?.length > 0 &&
      incidentId !== incidentIdParam &&
      token
    ) {
      setTsgQuestions([]);
      setSmeQuestions([]);
      handleIncidentChange(incidentIdParam);
    }
  }, [incidentIdParam, token]);

  const handleIncidentChange = async (incidentId: string) => {
    setPotentialIssuesDetectedForIncident(undefined);

    try {
      const [incidentInfo, incidentDescriptions] = await Promise.all([
        getIncidentInfo(incidentId),
        getIncidentDescriptions(incidentId),
      ]);

      if (incidentInfo) {
        if (!incidentInfo.title.toLowerCase().includes('canary sites')) {
          const error = 'incident_not_supported';
          navigate(`/?error=${encodeURIComponent(error)}`);
          return;
        }
        setIncidentId(incidentId);
        setIncidentInfo(incidentInfo);
        const { resourceType } =
          defaultResourceTypes.find(
            (resourceType) =>
              resourceType.tenantName.toLowerCase() ===
              incidentInfo.owningTenantName.toLowerCase()
          ) || {};
        setServiceName(incidentInfo.owningTenantName);
        setServiceType(resourceType?.toLowerCase() ?? '');
      }

      const filteredIncidentDescriptions = filterIncidentDescriptions(
        incidentDescriptions,
        chatModel
      );
      setIncidentDescriptions(filteredIncidentDescriptions);

      const incidentMetadata: ChatMessageAdditionalData = {
        incidentId,
        incidentTitle: incidentInfo?.title,
        ...(filteredIncidentDescriptions?.length > 0 && {
          incidentDescriptions: filteredIncidentDescriptions,
        }),
        ...(serviceName?.length > 0 && { serviceName }),
      };
      setIncidentMetadata(incidentMetadata);
      if (
        incidentInfo &&
        incidentInfo.title?.length > 0 &&
        filteredIncidentDescriptions.length > 0
      ) {
        const incidentData = sessionStorage.getItem(
          `IncidentData-${incidentId}`
        );
        if (incidentData) {
          const parsedData = JSON.parse(incidentData);
          setSmeQuestions(parsedData.smeQuestions);
          setTsgQuestions(parsedData.tsgQuestions);
          return;
        }
        setCurrentChatId(chatIdentifier);
        submitQuestionStreamForSignalR(
          '',
          {
            apiProtocol,
            chatModel,
            chatId: chatIdentifier,
            chatContextLen,
            setChatContextLen,
            customInitialPrompt:
              'You are an AI assistant that helps Azure support engineers to create TSG questions for an incident. You have access to the incident details and potential issues detected for the incident. You cannot ask any clarifying questions. You can also suggest TSG questions based on the incident details and potential issues detected.',
            responseTokenSize,
          },
          incidentMetadata
        );
      }
    } catch (error) {
      trackAppException({
        exception: error,
        properties: {
          eventName: 'handleIncidentChange',
          incidentId,
        },
      });
    }
  };

  const handleResponseChange = (response: string) => {
    const updatedResponse = StringUtilities.cleanResponseText(response);
    const { result, parsedJson: parsedResponse } = StringUtilities.isValidJSON<{
      tsgQuestions: string[];
      smeQuestions: string[];
    }>(updatedResponse);
    if (result && parsedResponse) {
      const { smeQuestions, tsgQuestions } = parsedResponse;
      setSmeQuestions(smeQuestions);
      setTsgQuestions(tsgQuestions);
      // save data in session storage
      sessionStorage.setItem(
        `IncidentData-${incidentId}`,
        JSON.stringify({ smeQuestions, tsgQuestions })
      );
    }
    // console.log('Response:', response);
  };

  const filterIncidentDescriptions = (
    incidentDescriptions: string[],
    chatModel: ChatModel
  ): string[] => {
    const keywordsToFilter = [
      'brain',
      'Acknowledging incident',
      'Incident created',
    ];

    if (!Array.isArray(incidentDescriptions)) {
      return [];
    }

    const filteredIncidentDescriptions = incidentDescriptions.filter(
      (description) =>
        !keywordsToFilter.some((keyword) =>
          description.toLowerCase().includes(keyword.toLowerCase())
        )
    );

    const plainText = convert(filteredIncidentDescriptions.join(' '));
    const trimmedDescriptions = plainText
      .split('\n')
      .filter((text) => text.trim().length > 0)
      .join(' ');

    const updatedIncidentDescriptions = checkAndTruncateData(
      trimmedDescriptions,
      chatModel
    );
    return updatedIncidentDescriptions.split('\n');
  };

  const truncateData = (prompt: string, tokenLimit: number): string => {
    const tokens = encode(prompt);
    // Truncate the tokens to fit within the max token limit
    const truncatedTokens = tokens.slice(0, tokenLimit);
    return decode(truncatedTokens);
  };

  const checkAndTruncateData = (prompt: string, model: ChatModel): string => {
    const maxTokens = ChatModelTokenLimits[model];
    const thresholdTokens = Math.floor(maxTokens * 0.75);
    return isWithinTokenLimit(prompt, thresholdTokens)
      ? prompt
      : truncateData(prompt, thresholdTokens);
  };

  const getCurrentOnCallDri = async (
    teamId: string
  ): Promise<OnCallDriInfo[]> => {
    // Call the API to get the current on-call DRI
    try {
      return await getReq(
        appLensBackendUrl,
        `/api/skylight/getCurrentOnCallDriInfo/${teamId}`,
        token
      );
    } catch (error) {
      trackAppException({
        exception: error,
        properties: {
          eventName: 'getCurrentOnCallDri',
          incidentId,
        },
      });
    }
    return [];
  };

  const getIncidentInfo = async (incidentId: string): Promise<IncidentInfo> => {
    try {
      return await getReq(
        appLensBackendUrl,
        `/api/skylight/getIncidentInfoById/${incidentId}`,
        token
      );
    } catch (error) {
      trackAppException({
        exception: error,
        properties: {
          eventName: 'getIncidentInfo',
          incidentId,
        },
      });
    }
    return {} as IncidentInfo;
  };

  const getPotentialIssuesDetectedForIncident = async (
    incidentId: string,
    validationResults: ValidationResults[]
  ): Promise<any> => {
    try {
      if (token && token.length > 0) {
        return await postReq(
          appLensBackendUrl,
          '/api/icm/validateAndUpdateIncident',
          token,
          {
            IncidentId: incidentId,
            ValidationResults: validationResults?.map((x) => ({
              Name: x.name,
              Value: x.value,
            })),
          }
        );
      }
    } catch (error) {
      trackAppException({
        exception: error,
        properties: {
          eventName: 'getPotentialIssuesDetectedForIncident',
          incidentId,
        },
      });
    }
    return {};
  };

  const getIncidentDescriptions = async (
    incidentId: string
  ): Promise<string[]> => {
    try {
      return await getReq(
        appLensBackendUrl,
        `/api/skylight/getIncidentDescriptions/${incidentId}`,
        token
      );
    } catch (error) {
      trackAppException({
        exception: error,
        properties: {
          eventName: 'getIncidentDescriptions',
          incidentId,
        },
      });
    }
    return [];
  };

  return (
    <IncidentContext.Provider
      value={{
        // smeData,
        incidentId,
        incidentInfo,
        incidentDescriptions,
        incidentMetadata,
        potentialIssuesDetectedForIncident,
        topIncidents,
        smeQuestions,
        tsgQuestions,
        serviceName,
        serviceType,
        setIncidentMetadata,
        // setSmeData,
        setServiceName,
        setServiceType,
        setIncidentDescriptions,
        setIncidentId,
        setIncidentInfo,
        setPotentialIssuesDetectedForIncident,
        setSmeQuestions,
        setTsgQuestions,
        setTopIncidents,
        getCurrentOnCallDri,
      }}
    >
      {children}
    </IncidentContext.Provider>
  );
};

const useIncidentContext = () => {
  const context = useContext(IncidentContext);
  if (!context) {
    throw new Error(
      'useIncidentContext must be used within an IncidentContext'
    );
  }
  return context;
};

export { IncidentProvider, useIncidentContext };
