import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import * as signalR from '@microsoft/signalr';
import { v4 as uuid } from 'uuid';
import {
  APIProtocol,
  ChatCompletionModel,
  ChatConfig,
  ChatMessage,
  ChatMessageAdditionalData,
  ChatModel,
  ChatProviderProps,
  ChatResponse,
  Conversation,
  FeedbackOptions,
  KeyValuePair,
  MessageRenderingType,
  MessageSource,
  MessageStatus,
  QueryTemperature,
  ResponseTokensSize,
  SuggestedPromptsResponse,
  TextCompletionModel,
  TextModels,
} from '../interfaces/OpenAiInterfaces';
import TimeUtilities from '../utils/TimeUtilities';
import { StringUtilities } from '../utils/StringUtilities';
import { getReq, postReq } from '../api/request';
import ChatContext from '../contexts/ChatContext';
import useAppInsights from '../hooks/useAppInsights';

const useChatContext = () => {
  const context = useContext(ChatContext);
  if (!context) {
    throw new Error('useChatContext must be used within a ChatProvider');
  }
  return context;
};

const ChatProvider: React.FC<ChatProviderProps> = ({ children, token }) => {
  const [chatResponse, setChatResponse] = useState<any>({});
  const [chatFinishReason, setChatFinishReason] = useState<any>({});
  const [connection, setConnection] = useState<signalR.HubConnection | null>(
    null
  );
  const [isEnabled, setIsEnabled] = useState(false);
  const [signalRLogLevel, setSignalRLogLevel] = useState<signalR.LogLevel>(
    signalR.LogLevel.Information
  );
  const [tsgCollectionName, setTsgCollectionName] = useState<string>('');
  const messageStoreRef = useRef<any>({});
  const appLensBackendUrl = process.env.REACT_APP_APPLENS_BACKEND_URL!;
  const skylightBackendUrl = process.env.REACT_APP_SKYLIGHT_BACKEND_URL!;
  const signalRChatEndpoint = `${skylightBackendUrl}/chathub`;
  const textCompletionApiPath = '/api/openai/runTextCompletion';
  const chatCompletionApiPath = '/api/openai/runChatCompletion';
  const [currentChatId, setCurrentChatId] = useState<string>('');
  const { trackAppException } = useAppInsights();

  useEffect(() => {
    if (process.env.NODE_ENV === 'development') {
      setSignalRLogLevel(signalR.LogLevel.Debug);
    }
    resetChatId();
  }, []);

  useEffect(() => {
    if (token && token.length > 0 && isEnabled) {
      establishSignalRConnection();
    }
  }, [token, isEnabled]);

  useEffect(() => {
    if (token && token.length > 0) {
      checkOpenAIEnabled()
        .then((result) => {
          // console.log('is enabled', result);
          setIsEnabled(result);
        })
        .catch((error) => {
          // console.error('Error:', error);
          setIsEnabled(false);
        });
    }
  }, [token]);

  useEffect(() => {
    // const currentChatId = sessionStorage.getItem('chatId') || '';
    if (connection && currentChatId.length > 0) {
      // console.log('chat id', currentChatId);
      const handleMessageReceived = (message: any) => {
        if (message != null) {
          const parsedMessage = JSON.parse(message);
          // console.log('Message received: ', parsedMessage);
          setChatFinishReason((prevData: any) => {
            return {
              ...prevData,
              [currentChatId]: parsedMessage.FinishReason,
            };
          });
          setChatResponse((prevResponse: any) => {
            const currentMessages = prevResponse[currentChatId] || '';
            // console.log('prevResponse', prevResponse, currentMessages);
            return {
              ...prevResponse,
              [currentChatId]: currentMessages + parsedMessage.Content,
            };
          });
          // if (parsedMessage.FinishReason.toLowerCase() === 'stop') {
          //   //resetChatId();
          //   sessionStorage.removeItem('chatId');
          // }
        }
      };

      const handleMessageCancelled = (reason: string) => {
        setChatResponse((prevResponse: any) => {
          return {
            ...prevResponse,
            [currentChatId]: 'Message cancelled by system. ' + reason,
          };
        });
        // resetChatId();
      };

      connection.on('MessageReceived', handleMessageReceived);
      connection.on('MessageCancelled', handleMessageCancelled);

      // Return a cleanup function to remove the event listeners
      return () => {
        connection.off('MessageReceived', handleMessageReceived);
        connection.off('MessageCancelled', handleMessageCancelled);
      };
    }
  }, [connection, currentChatId]);

  const sendChatMessage = useCallback(
    async (
      queryModel: ChatCompletionModel,
      customPrompt = '',
      autoAddResourceSpecificInfo = true
    ): Promise<{ sent: boolean; failureReason: string }> => {
      try {
        if (customPrompt?.length > 0) {
          queryModel.metadata['customPrompt'] = customPrompt;
        }
        // queryModel.metadata["azureServiceName"] = _resourceService.searchSuffix;
        // queryModel.metadata["armResourceId"] = _resourceService.getCurrentResourceId();

        // if (autoAddResourceSpecificInfo) {
        //   queryModel.metadata['resourceSpecificInfo'] =
        //     convertKeyValuePairArrayToObj([{ key: 'test', value: 'test' }]);
        //   // queryModel.metadata["resourceSpecificInfo"] = convertKeyValuePairArrayToObj(resourceSpecificInfo);
        // }

        await connection?.send('sendMessage', JSON.stringify(queryModel));
        return { sent: true, failureReason: '' };
      } catch (error: any) {
        console.error('Sending message failed: ', error.toString());
        trackAppException({
          exception: error.toString(),
          severityLevel: 2,
          properties: {
            eventName: 'SendChatMessage',
            chatId: currentChatId,
          },
        });
        return { sent: false, failureReason: error.toString() };
      }
    },
    [connection]
  );

  const establishSignalRConnection = async () => {
    if (token && token.length > 0) {
      const conn = new signalR.HubConnectionBuilder()
        .withUrl(signalRChatEndpoint, {
          accessTokenFactory: () => token,
        })
        .configureLogging(signalRLogLevel)
        .withAutomaticReconnect()
        .build();

      try {
        await conn.start();
        customLogger(
          'SignalRConnectionEstablished',
          'SignalR Connection established'
        );
        setConnection(conn);
      } catch (err: any) {
        console.error('SignalR Connection failed: ', err.toString());
        trackAppException({
          exception: err,
          severityLevel: 2,
          properties: {
            eventName: 'establishSignalRConnection',
          },
        });
        customLogger(
          'SignalRConnectionFailed',
          `SignalR Connection failed: ${err.toString()}`
        );
      }
    }
  };

  const cancelChatMessage = useCallback(
    async (messageId: string) => {
      try {
        await connection?.send('CancelMessage', messageId);
      } catch (err: any) {
        console.error('Cancelling message failed: ', err.toString());
      }
    },
    [connection]
  );

  const generateTextCompletion = useCallback(
    async (
      queryModel: TextCompletionModel,
      customPrompt = '',
      caching = true,
      insertCustomPromptAtEnd = false,
      ignoreCache = false
    ): Promise<ChatResponse> => {
      try {
        if (token && token.length > 0) {
          if (customPrompt?.length > 0) {
            queryModel.prompt = insertCustomPromptAtEnd
              ? `${queryModel.prompt}\n${customPrompt}`
              : `${customPrompt}\n${queryModel.prompt}`;
          } else {
            const tempProductName = 'AppLens';
            queryModel.prompt = `You are helping engineers to debug issues related to ${tempProductName}. Do not be repetitive when providing steps in your answer. Please answer the below question\n${queryModel.prompt}`;
          }

          return await postReq(
            skylightBackendUrl,
            textCompletionApiPath,
            token,
            { payload: queryModel },
            { 'x-ms-openai-cache': caching.toString() },
            true,
            false,
            ignoreCache
          );
        }
        return {
          text: '',
          truncated: false,
          finishReason: '',
          feedbackIds: [],
          exception: 'Token not found',
        };
      } catch (error: any) {
        console.error('Error:', error);
        trackAppException({
          exception: error,
          severityLevel: 2,
          properties: {
            eventName: 'generateTextCompletion',
          },
        });
        return {
          text: '',
          truncated: false,
          finishReason: '',
          feedbackIds: [],
          exception: error.toString(),
        };
      }
    },
    [token]
  );

  const getChatCompletion = useCallback(
    async (
      queryModel: ChatCompletionModel,
      customPrompt = '',
      autoAddResourceSpecificInfo = true
    ): Promise<ChatResponse> => {
      try {
        if (token && token.length > 0) {
          if (customPrompt?.length > 0) {
            queryModel.messages.unshift({
              role: 'user',
              content: customPrompt,
            });
          }
          // queryModel.metadata['armResourceId'] = 'Your Resource ID'; // replace with your resource ID

          if (autoAddResourceSpecificInfo) {
            // queryModel.metadata["resourceSpecificInfo"] = convertKeyValuePairArrayToObj("Your Resource Specific Info");  // replace with your resource specific info conversion function
          }

          return await postReq<ChatResponse, ChatCompletionModel>(
            skylightBackendUrl,
            chatCompletionApiPath,
            token,
            queryModel,
            { 'x-ms-openai-cache': 'True' }
          );
        }
        return {
          text: '',
          truncated: false,
          finishReason: '',
          feedbackIds: [],
          exception: 'Token not found',
        };
      } catch (error: any) {
        trackAppException({
          exception: error,
          severityLevel: 2,
          properties: {
            eventName: 'getChatCompletion',
          },
        });
        return {
          text: '',
          truncated: false,
          finishReason: '',
          feedbackIds: [],
          exception: error.toString(),
        };
      }
    },
    [token]
  );

  const getSuggestionPrompts = async (
    conversationHistory: Conversation[],
    productIdentifier: string,
    resourceId?: string
  ): Promise<SuggestedPromptsResponse> => {
    try {
      if (token && token.length > 0) {
        const chatMessages = conversationHistory.slice(-4);
        const combinedMessages = chatMessages
          .map((x) =>
            x.role === 'user'
              ? `Customer: ${x.content}`
              : `AI Assistant: ${x.content}`
          )
          .join('\n');
        const userInput = combinedMessages
          .replace(/\\/g, '\\\\')
          .replace(/\n/g, '\\n')
          .replace(/"/g, '\\"');
        const requestBody = {
          skillName: 'TSGSuggestedPromptSkill',
          functionName: 'GenerateSuggestionPrompts',
          gptModelVersion: 2,
          userInput,
          contextVariables: {
            sessionSummary: 'Empty session',
            productIdentifier,
          },
        };

        const response = await postReq<string, any>(
          appLensBackendUrl,
          '/api/skylight/invokeSemanticServiceSkill/',
          token,
          requestBody
        );
        if (response?.length > 0) {
          const { result, parsedJson: parsedResponse } =
            StringUtilities.isValidJSON<SuggestedPromptsResponse>(response);
          if (result && parsedResponse) {
            return parsedResponse;
          }
        }
      }
    } catch (error: any) {
      trackAppException({
        exception: error,
        severityLevel: 2,
        properties: {
          eventName: 'getSuggestionPrompts',
        },
      });
    }
    return {} as SuggestedPromptsResponse;
  };

  const convertKeyValuePairArrayToObj = (keyValueArray: KeyValuePair[]) => {
    const obj: Record<string, any> = {};
    keyValueArray.forEach((keyValue: KeyValuePair) => {
      obj[keyValue.key] = keyValue.value;
    });
    return obj;
  };

  const customLogger = (event: string, message: string) => {
    const eventStr = `ApplensOpenAIService-${event}`;
    const time = new Date().getTime().toString();
    // if (process.env.NODE_ENV === 'production') {
    //   // this.telemetryService.logEvent(eventStr, { message: message, ts: time });
    // } else {
    //   // console.log(`event: ${eventStr}, message: ${message}, ts: ${time}`);
    // }
  };

  const checkOpenAIEnabled = async (): Promise<boolean> => {
    try {
      return await getReq(skylightBackendUrl, '/api/openai/enabled', token);
    } catch (error: any) {
      trackAppException({
        exception: error,
        severityLevel: 2,
        properties: {
          eventName: 'checkOpenAIEnabled',
        },
      });
      return false;
    }
  };

  const submitQuestionStreamForSignalR = async (
    trimmedTextContent: string,
    chatConfig: ChatConfig,
    additionalData?: ChatMessageAdditionalData
  ) => {
    try {
      const { apiProtocol, chatModel, chatId } = chatConfig;
      // setCurrentChatId(chatId);
      setChatResponse((prevResponse: any) => {
        return {
          ...prevResponse,
          [chatId]: '',
        };
      });
      // sessionStorage.setItem('chatId', chatId);
      const message = {
        id: uuid(),
        displayMessage: trimmedTextContent,
        message: trimmedTextContent,
        messageSource: MessageSource.User,
        timestamp: new Date().getTime(),
        messageDisplayDate: TimeUtilities.displayMessageDate(new Date()),
        status: MessageStatus.Finished,
        userFeedback: FeedbackOptions.None,
        renderingType: MessageRenderingType.Text,
        data: additionalData,
      };

      const currentMessageStore: any[] = [];
      if (!messageStoreRef.current.hasOwnProperty(chatId)) {
        messageStoreRef.current = { ...messageStoreRef.current, [chatId]: [] };
      }

      if (checkMessageQuota(chatId)) {
        currentMessageStore.push(message);
        const chatMessage = {
          id: uuid(),
          message: '',
          displayMessage: '',
          messageSource: MessageSource.System,
          timestamp: new Date().getTime(),
          messageDisplayDate: TimeUtilities.displayMessageDate(new Date()),
          status: MessageStatus.Created,
          userFeedback: FeedbackOptions.None,
          renderingType: MessageRenderingType.Text,
          feedbackDocumentIds: [],
          data: message.data,
        };
        currentMessageStore.push(chatMessage);
        messageStoreRef.current = {
          ...messageStoreRef.current,
          [chatId]: currentMessageStore,
        };

        if (apiProtocol === APIProtocol.Rest || chatModel === ChatModel.GPT3) {
          await fetchOpenAIResultUsingRest(
            prepareChatContext(currentMessageStore, chatConfig),
            chatMessage,
            chatConfig
          );
        } else if (apiProtocol === APIProtocol.WebSocket) {
          await fetchOpenAIResultUsingWSS(
            prepareChatContext(currentMessageStore, chatConfig),
            chatMessage,
            chatConfig
          );
        }
      }
    } catch (error: any) {
      trackAppException({
        exception: error,
        severityLevel: 2,
        properties: {
          eventName: 'submitQuestionStreamForSignalR',
        },
      });
    }
  };

  const fetchOpenAIResultUsingWSS = async (
    searchQuery: any,
    messageObj: ChatMessage,
    chatConfig: ChatConfig
  ) => {
    try {
      const {
        chatModel,
        customInitialPrompt,
        chatId,
        autoAddResourceSpecificInfoToChatMessages,
        responseTokenSize,
      } = chatConfig;

      resetChatRequestError();
      const chatCompletionQueryModel = createChatCompletionModel(
        searchQuery,
        messageObj.id,
        chatId,
        chatModel,
        responseTokenSize,
        messageObj.data
      );
      const response = await sendChatMessage(
        chatCompletionQueryModel,
        customInitialPrompt,
        autoAddResourceSpecificInfoToChatMessages
      );
    } catch (error: any) {
      trackAppException({
        exception: error,
        severityLevel: 2,
        properties: {
          eventName: 'fetchOpenAIResultUsingWSS',
        },
      });
    }
  };

  const prepareChatContext = (messages: ChatMessage[], chatConfig: any) => {
    const { chatModel, chatContextLen, useDisplayMessageForChatHistory } =
      chatConfig;
    //Take last 'chatContextLength' messages to build context
    let context: any;
    const messagesToConsider = messages.slice(-1 * chatContextLen);

    if (chatModel == ChatModel.GPT3) {
      context = messagesToConsider
        .map(
          (
            { messageSource, message, displayMessage }: ChatMessage,
            index: number
          ) => {
            if (index >= messagesToConsider.length - 2) {
              return `${messageSource}: ${message}`;
            }
            return `${messageSource}: ${displayMessage && useDisplayMessageForChatHistory ? displayMessage : message}`;
          }
        )
        .join('\n');
    } else {
      context = [];
      messagesToConsider.forEach(
        (
          { message, messageSource, displayMessage }: ChatMessage,
          index: number
        ) => {
          const role =
            messageSource == MessageSource.User ? 'User' : 'Assistant';
          const content =
            index >= messagesToConsider.length - 2
              ? message
              : displayMessage && useDisplayMessageForChatHistory
                ? displayMessage
                : message;

          if (content != '') {
            context.push({
              role: role,
              content: content,
            });
          }
        }
      );
    }
    return context;
  };

  const createChatCompletionModel = (
    chatMessages: any[],
    messageId: string,
    chatIdentifier: string,
    chatModel: ChatModel = ChatModel.GPT4,
    responseSize: ResponseTokensSize = ResponseTokensSize.Small,
    additionalData?: ChatMessageAdditionalData
  ): ChatCompletionModel => {
    const {
      incidentTitle,
      incidentDescriptions,
      potentialIssuesDetected,
      serviceName,
      // smeData,
    } = additionalData || {};
    return {
      metadata: {
        chatIdentifier,
        chatModel,
        maxTokens: responseSize,
        messageId,
        ...(incidentTitle &&
          incidentTitle?.length > 0 && {
            incidentTitle,
          }),
        ...(incidentDescriptions &&
          incidentDescriptions?.length > 0 && {
            incidentDescriptions: incidentDescriptions.join('\n'),
          }),
        ...(potentialIssuesDetected &&
          potentialIssuesDetected?.length > 0 && {
            detectedIssues: potentialIssuesDetected.join('\n'),
          }),
        ...(serviceName &&
          serviceName.length > 0 && { azureServiceName: serviceName }),
        // ...(smeData && smeData.length > 0 && { smeData }),
      },
      messages: chatMessages,
    };
  };

  const createTextCompletionModel = (
    text: string,
    model: TextModels = TextModels.Default,
    responseSize: ResponseTokensSize = ResponseTokensSize.Small,
    queryTemperature: QueryTemperature = QueryTemperature.Low
  ): TextCompletionModel => {
    return {
      model: model,
      prompt: `${text}`,
      temperature: queryTemperature,
      max_tokens: responseSize,
    };
  };

  const fetchOpenAIResultUsingRest = async (
    searchQuery: any,
    messageObj: ChatMessage,
    chatConfig: any,
    retry = true,
    trimNewline = false
  ) => {
    try {
      const {
        chatModel,
        chatContextLen,
        chatId,
        customInitialPrompt,
        responseTokenSize,
        autoAddResourceSpecificInfoToChatMessages,
      } = chatConfig;
      let response: ChatResponse;
      if (chatModel === ChatModel.GPT3) {
        const openAiQueryModel = createTextCompletionModel(
          searchQuery,
          TextModels.Default,
          responseTokenSize
        );
        response = await generateTextCompletion(
          openAiQueryModel,
          customInitialPrompt,
          true
        );
      } else {
        const openAiQueryModel = createChatCompletionModel(
          searchQuery,
          messageObj.id,
          chatId,
          chatModel,
          responseTokenSize,
          messageObj.data
        );
        response = await getChatCompletion(
          openAiQueryModel,
          customInitialPrompt,
          autoAddResourceSpecificInfoToChatMessages
        );
      }

      const trimmedText =
        chatModel === ChatModel.GPT3
          ? trimNewline
            ? StringUtilities.TrimBoth(response.text)
            : StringUtilities.TrimEnd(response.text)
          : response.text;

      messageObj.message = StringUtilities.mergeOverlappingStrings(
        messageObj.message,
        trimmedText
      );
      messageObj.status =
        response.truncated === true
          ? MessageStatus.InProgress
          : MessageStatus.Finished;

      // Check if the ids in response.feedbackIds are already present in messageObj.feedbackDocumentIds. If not, add them to messageObj.feedbackDocumentIds
      if (response?.feedbackIds?.length > 0) {
        response.feedbackIds.forEach((id) => {
          if (messageObj?.feedbackDocumentIds?.indexOf(id) === -1) {
            messageObj.feedbackDocumentIds.push(id);
          }
        });
      }

      messageObj =
        postProcessSystemMessage == null
          ? Object.assign(messageObj, {
              displayMessage: StringUtilities.mergeOverlappingStrings(
                messageObj.displayMessage,
                trimmedText
              ),
            })
          : postProcessSystemMessage(messageObj);

      if (response.truncated) {
        //Do not trim newline for the next query
        const messagesToConsider = messageStoreRef.current[chatId].slice(
          -1 * chatContextLen
        );

        await fetchOpenAIResultUsingRest(
          prepareChatContext(messagesToConsider, chatConfig),
          messageObj,
          chatConfig,
          retry,
          false
        );
        // this.chatUIComponentRef.scrollToBottom();
      } else {
        markMessageCompleted(messageObj);
        // if (setCurrentApiCallCount) setCurrentApiCallCount(0);
        if (setChatResponse) {
          setChatResponse((prevResponse: any) => {
            return {
              ...prevResponse,
              [chatId]: response.text,
            };
          });
        }
      }
    } catch (error: any) {
      trackAppException({
        exception: error,
        severityLevel: 2,
        properties: {
          eventName: 'fetchOpenAIResultUsingRest',
        },
      });
      if (error?.status == 400) {
        //Sometimes the chat context may become too long for the API to handle. In that case, we reduce the chat context length by 2 and retry
        // this._telemetryService.logEvent("OpenAIChatBadRequestError", { ...err, chatIdentifier: this.chatIdentifier, userId: this._chatContextService.userId, ts: new Date().getTime().toString() });
        // if (setChatContextLen)
        //   setChatContextLen(chatContextLen - 2 >= 0 ? chatContextLen - 2 : 0);
        await fetchOpenAIResultUsingRest(
          searchQuery,
          messageObj,
          chatConfig,
          (retry = false)
        );
      } else if (retry) {
        await fetchOpenAIResultUsingRest(
          searchQuery,
          messageObj,
          chatConfig,
          (retry = false)
        );
      } else {
        handleFailure(error, messageObj);
      }
    }
  };

  const handleFailure = (err: any, messageObj: any) => {
    if (err?.status == 429) {
      //   this._telemetryService.logEvent("OpenAIChatTooManyRequestsError", { ...err, chatIdentifier: this.chatIdentifier, userId: this._chatContextService.userId, ts: new Date().getTime().toString() });
      displayChatRequestError(
        'Ah! Too many people asking me questions! Please try again in sometime.'
      );
    } else {
      //   this._telemetryService.logEvent("OpenAIChatRequestError", { ...err, chatIdentifier: this.chatIdentifier, userId: this._chatContextService.userId, ts: new Date().getTime().toString() });
      displayChatRequestError(
        'Me and AppLens are on a talking freeze it seems. Lets try again later.'
      );
    }

    markMessageCompleted(messageObj);
    // reEnableChatBox();
    // if (setCurrentApiCallCount) setCurrentApiCallCount(0);
    // updateChatProfileSetting('currentApiCallCount', 0);
  };

  const markMessageCompleted = (
    messageObj: ChatMessage,
    status: MessageStatus = MessageStatus.Finished
  ) => {
    messageObj.status = status;
    messageObj.timestamp = new Date().getTime();
    messageObj.messageDisplayDate = TimeUtilities.displayMessageDate(
      new Date()
    );
  };

  //TODO: To be updated with the actual implementation
  const displayChatRequestError = (errorMessage: string) => {
    // // setShowChatRequestError(true);
    // setOpenAIChatRequestError(errorMessage);

    // Hide the error message after 5 seconds
    setTimeout(() => {
      resetChatRequestError();
    }, 5000);
  };

  // const resetChatResponse = () => {
  //   // adding an artifical delay before reset to wait for any messages in flight
  //   setTimeout(() => {
  //     setChatResponse((prevResponse: any) => {
  //       return {
  //         ...prevResponse,
  //         [currentChatId]: '',
  //       };
  //     });
  //   }, 500);
  // };

  const resetChatId = () => {
    // setTimeout(() => {
    setCurrentChatId('');
    // }, 500);
  };

  //TODO: To be updated with the actual implementation
  const postProcessSystemMessage = (messageObj: ChatMessage) => {
    return messageObj;
  };

  const resetChatRequestError = () => {
    // setShowChatRequestError(false);
    // setOpenAIChatRequestError('');
    // updateChatProfileSetting('showChatRequestError', false);
    // updateChatProfileSetting('openAIChatRequestError', '');
  };

  const checkMessageQuota = (chatIdentifier: string): boolean => {
    // if (!quotaEnforced) {
    //   return true;
    // }
    if (messageStoreRef.current[chatIdentifier]?.length > 0) {
      const messageDailyCount = messageStoreRef.current[chatIdentifier].filter(
        (m: ChatMessage) =>
          m.messageSource == MessageSource.User &&
          TimeUtilities.checkSameDay(m.timestamp)
      ).length;
      // if (messageDailyCount > dailyMessageQuota) {
      // Replace with your telemetry service
      // console.log("OpenAIChatMessageQuotaExceeded", {
      //   chatIdentifier: chatIdentifier,
      //   userId: userId,
      //   messageCount: messageDailyCount.toString(),
      //   quotaLimit: dailyMessageQuota.toString(),
      //   ts: new Date().getTime().toString()
      // });

      // setShowMessageQuotaError(true);
      // setMessageQuotaErrorMessage(
      //   `You have exhausted your daily quota of ${dailyMessageQuota} messages. We are working on increasing the quota capacity.`
      // );
      // setShowMessageQuotaWarning(false);
      // setMessageQuotaWarningMessage('');
      //   return false;
      // }
      // else if (
      //   messageDailyCount >=
      //   dailyMessageQuota - messageQuotaWarningThreshold
      // ) {
      //   // setShowMessageQuotaWarning(true);
      //   // setMessageQuotaErrorMessage('');
      //   // setMessageQuotaWarningMessage(
      //   //   `You have used ${messageDailyCount} out of the daily quota limit of ${dailyMessageQuota} messages.`
      //   // );
      //   // setShowMessageQuotaError(false);
      // }
    }
    return true;
  };

  return (
    <ChatContext.Provider
      value={{
        createChatCompletionModel,
        chatResponse,
        setChatResponse,
        setCurrentChatId,
        tsgCollectionName,
        setTsgCollectionName,
        chatFinishReason,
        setChatFinishReason,
        submitQuestionStreamForSignalR,
        getChatCompletion,
        getSuggestionPrompts,
      }}
    >
      {children}
    </ChatContext.Provider>
  );
};

export { ChatProvider, useChatContext };
