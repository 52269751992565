/* eslint-disable @typescript-eslint/no-empty-function, @typescript-eslint/no-unused-vars, @typescript-eslint/no-explicit-any */
import { createContext } from 'react';

const UserContext = createContext({
  token: '' as string | null,
  setToken: (value: string) => {},
  username: '',
  setUsername: (value: string) => {},
});

export default UserContext;
