import React, { Suspense, lazy } from 'react';
import { Routes, Route, Navigate, Outlet } from 'react-router-dom';
import { PageLayout } from './ui-components/PageLayout';
import SpinningComponent from './ui-components/Spinner';
import MiniDrawer from './ui-components/Drawer';
import useAuthGuard from './hooks/useAuthGuard';

const Home = lazy(() => import('./pages/Home'));
const TSG = lazy(() => import('./pages/TroubleshootingGuide'));
const SME = lazy(() => import('./pages/SubjectMatterExpert'));
const RCA = lazy(() => import('./pages/RCACopilot'));
const NetworkTraceAnalyzer = lazy(() => import('./pages/NetworkTraceAnalyzer'));
const UploadTsgComponent = lazy(() => import('./pages/UploadTsgComponent'));
const DownPage = lazy(() => import('./pages/DownPage'));
const UnauthorizedPage = lazy(() => import('./pages/UnauthorizedPage'));
const TokenInvalidPage = lazy(() => import('./pages/TokenInvalidPage'));

function App() {
  return (
    <PageLayout>
      <MiniDrawer>
        <Suspense fallback={<SpinningComponent message="Loading..." />}>
          <Routes>
            <Route path="/unauthorized" element={<UnauthorizedPage />} />
            <Route path="/tokeninvalid" element={<TokenInvalidPage />} />
            <Route path="/downpage" element={<DownPage />} />
            <Route element={<ProtectedRoute />}>
              <Route path="/tsg-copilot" element={<TSG />} />
              <Route path="/sme-assistant" element={<SME />} />
              <Route path="/rca-copilot" element={<RCA />} />
              <Route
                path="/network-trace-analyzer"
                element={<NetworkTraceAnalyzer />}
              />
              <Route path="/upload-tsg" element={<UploadTsgComponent />} />
              <Route path="/" element={<Home />} />{' '}
            </Route>
          </Routes>
        </Suspense>
      </MiniDrawer>
    </PageLayout>
  );
}

const ProtectedRoute: React.FC = () => {
  const isAuthorized = useAuthGuard();

  if (isAuthorized === null) {
    return <SpinningComponent message="Loading..." />;
  }

  return isAuthorized ? <Outlet /> : <Navigate to="/unauthorized" />;
};

export default App;
