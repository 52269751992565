import { ResourceTypeState } from '../../interfaces/ResourceInterfaces';

export const defaultResourceTypes: ResourceTypeState[] = [
  {
    resourceType: 'Microsoft.Web/sites',
    tenantName: 'App Service (Web Apps)',
    enabled: true,
    id: 'App Service',
  },
  //   {
  //     resourceType: 'Microsoft.Web/hostingEnvironments',
  //     resourceTypeLabel: 'ASE name',
  //     tenantName: 'App Service Environment',
  //     enabled: true,
  //     id: 'App Service Environment',
  //   },
  //   {
  //     resourceType: 'Microsoft.Web/containerApps',
  //     resourceTypeLabel: 'Container App Name',
  //     tenantName: 'Container App',
  //     enabled: true,
  //     id: 'Container App',
  //   },
  //   {
  //     resourceType: 'Microsoft.Web/staticSites',
  //     resourceTypeLabel: 'Static App Name Or Default Host Name',
  //     tenantName: 'Static Web App',
  //     enabled: true,
  //     id: 'Static Web App',
  //   },
  //   {
  //     resourceType: 'Microsoft.ApiManagement/service',
  //     resourceTypeLabel: 'APIM Service Name',
  //     tenantName: 'APIM Service',
  //     enabled: true,
  //     id: 'APIM Service',
  //   },
  //   {
  //     resourceType: 'Microsoft.Compute/virtualMachines',
  //     resourceTypeLabel: 'Virtual machine Id',
  //     tenantName: 'Virtual Machine',
  //     enabled: true,
  //     id: 'Virtual Machine',
  //   },
  //   {
  //     resourceType: 'ARMResourceId',
  //     resourceTypeLabel: 'ARM Resource ID',
  //     tenantName: 'ARM Resource ID',
  //     enabled: true,
  //     id: 'ARM Resource ID',
  //   },
  //   {
  //     resourceTypeLabel: 'Stamp name',
  //     tenantName: 'Internal Stamp',
  //     enabled: true,
  //     id: 'Internal Stamp',
  //   },
];
