import { Button, ListItemButton } from '@mui/material';
import { useNavigate, useSearchParams } from 'react-router-dom';

interface NavLinkProps {
  to: string;
  children: React.ReactNode;
}

const NavLink: React.FC<NavLinkProps> = ({ to, children }) => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const handleClick = (
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>
  ) => {
    event.preventDefault();
    // Preserve the existing query parameters.
    navigate(`${to}?${searchParams.toString()}`);
  };

  return (
    <ListItemButton href={to} onClick={handleClick}>
      {children}
    </ListItemButton>
  );
};

export default NavLink;
