import moment from 'moment';

export default class TimeUtilities {
  static yearAndDateFormat = 'YYYY-MM-DD';
  static timeFormat = 'HH:mm';
  static fullStringFormat = `${TimeUtilities.yearAndDateFormat} ${TimeUtilities.timeFormat}`;

  static roundDownByMinute(date: moment.Moment, minutes: number) {
    date.startOf('minute').minute(date.minute() - (date.minute() % minutes));
  }

  static roundDown(date: moment.Moment, duration: moment.Duration) {
    if (duration.months() > 0) {
      date
        .startOf('month')
        .month(date.month() - (date.month() % duration.months()));
    }
    if (duration.days() > 0) {
      date.startOf('day').days(date.days() - (date.days() % duration.days()));
    }
    if (duration.hours() > 0) {
      date
        .startOf('hour')
        .hours(date.hours() - (date.hours() % duration.hours()));
    }
    if (duration.minutes() > 0) {
      date
        .startOf('minute')
        .minutes(date.minutes() - (date.minutes() % duration.minutes()));
    }
  }

  static convertMomentToDateAndTime(m: moment.Moment): {
    date: Date;
    time: string;
  } {
    const date = new Date(m.year(), m.month(), m.date(), m.hour());
    const time = m.format(TimeUtilities.timeFormat);

    return { date, time };
  }

  static convertDateAndTimeToMoment(
    date: Date,
    time: string,
    isUTC = true
  ): moment.Moment {
    const [hour, minute] = time.split(':').map(Number);
    const dateTimeArray = [
      date.getFullYear(),
      date.getMonth(),
      date.getDate(),
      hour,
      minute,
    ];

    return isUTC ? moment.utc(dateTimeArray) : moment(dateTimeArray);
  }

  static passDateFromString(s = ''): Date | null {
    const m = moment(s, TimeUtilities.yearAndDateFormat);

    return m.isValid() ? m.toDate() : null;
  }

  static formatDate(date: Date): string {
    return moment(date).format(TimeUtilities.yearAndDateFormat);
  }

  static checkSameDay(daytimestamp: number) {
    const today = new Date().setHours(0, 0, 0, 0);
    const thatDay = new Date(daytimestamp).setHours(0, 0, 0, 0);

    return today === thatDay;
  }

  static displayMessageDate(timestamp: any) {
    return `${timestamp.getHours()}:${timestamp.getMinutes()} ${timestamp.getMonth() + 1}/${timestamp.getDate()}/${timestamp.getFullYear()}`;
  }

  static getCurrentUTCMoment = () => {
    return moment.utc();
  };

  static getCurrentUTCString = (
    stringFormat: string = this.fullStringFormat
  ): string => {
    return this.getCurrentUTCMoment().format(stringFormat);
  };
}
