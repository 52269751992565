import axios, { AxiosResponse } from 'axios';

const createInstance = (baseURL: string) => {
  return axios.create({
    baseURL,
  });
};

const request = <T>(
  baseURL: string,
  method: 'get' | 'post' | 'put' | 'delete',
  url: string,
  token: string | null,
  additionalHeaders?: any,
  data?: any,
  params?: any
): Promise<AxiosResponse<T>> => {
  const instance = createInstance(baseURL);
  const headers = {
    ...additionalHeaders,
    Authorization: `Bearer ${token}`,
  };
  return instance.request<T>({
    method,
    url,
    data,
    params,
    headers,
  });
};

const postReq = async <T, S>(
  apiUrl: string,
  path: string,
  token: string,
  body?: S,
  additionalHeaders: HeadersInit = {},
  invalidateCache = true,
  ignoreInFlightCall = false,
  ignoreCache = false
): Promise<T> => {
  const response = await request<T>(
    apiUrl,
    'post',
    path,
    token,
    additionalHeaders,
    body
  );

  // if you have a caching service implemented, you could use it here

  return response?.data;
};

const getReq = async <T>(
  apiUrl: string,
  path: string,
  token: string | null,
  params?: any,
  additionalHeaders: HeadersInit = {}
): Promise<T> => {
  const response = await request<T>(
    apiUrl,
    'get',
    path,
    token,
    additionalHeaders,
    null,
    params
  );
  return response.data;
};

const putReq = async <T, S>(
  apiUrl: string,
  path: string,
  token: string,
  body?: S,
  params?: any,
  additionalHeaders: HeadersInit = {}
): Promise<T> => {
  const response = await request<T>(
    apiUrl,
    'put',
    path,
    token,
    additionalHeaders,
    body,
    params
  );
  return response.data;
};

const deleteReq = async <T>(
  apiUrl: string,
  path: string,
  token: string,
  params?: any,
  additionalHeaders: HeadersInit = {}
): Promise<T> => {
  const response = await request<T>(
    apiUrl,
    'delete',
    path,
    token,
    additionalHeaders,
    null,
    params
  );
  return response.data;
};

export { postReq, getReq, putReq, deleteReq };
