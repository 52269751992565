import React from 'react';
import { useAppInsightsContext } from '@microsoft/applicationinsights-react-js';
import { ICustomProperties } from '@microsoft/applicationinsights-web';

const useAppInsights = () => {
  const appInsights = useAppInsightsContext();

  const trackAppEvent = ({
    name,
    properties,
  }: {
    name: string;
    properties?: ICustomProperties;
  }) => {
    appInsights.trackEvent(
      {
        name,
      },
      properties
    );
  };

  const trackAppPageView = ({
    name,
    properties,
  }: {
    name: string;
    properties?: ICustomProperties;
  }) => {
    appInsights.trackPageView({
      name,
      properties,
    });
  };

  const trackAppException = ({
    exception,
    properties,
    severityLevel,
  }: {
    exception: any;
    properties?: ICustomProperties;
    severityLevel?: number;
  }) => {
    appInsights.trackException(
      {
        exception,
        severityLevel,
      },
      properties
    );
  };

  return {
    trackAppEvent,
    trackAppException,
    trackAppPageView,
  };
};

export default useAppInsights;
