import React, { useState, ReactNode } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import { useMsal } from '@azure/msal-react';
import styled, { css } from 'styled-components';
import { useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import {
  ChevronRight as ChevronRightIcon,
  ChevronLeft as ChevronLeftIcon,
  Menu as MenuIcon,
  PersonSearch as SMEAssistantIcon,
  Troubleshoot as TSGCopilotIcon,
  Chat,
  AccountCircle,
  Notifications as NotificationsIcon,
  Home as HomeIcon,
} from '@mui/icons-material';
import {
  Box,
  CssBaseline,
  Divider,
  Drawer as MuiDrawer,
  IconButton,
  Link,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Toolbar,
  AppBar,
  Avatar,
  Menu,
  MenuItem,
  Badge,
} from '@mui/material';
import { Avatar as FluentUIAvatar } from '@fluentui/react-components';
import NavLink from './NavLink';
import NetworkTraceAnalyzerIcon from '../assets/img/network-trace-analyzer.svg';
import KQLAssistantIcon from '../assets/img/kql-assistant.svg';
import { useUserContext } from '../providers/UserProvider';
import { appInsights } from '../utils/AppInsightService';
import { name } from '@azure/msal-browser/dist/packageMetadata';
import { url } from 'inspector';
import { icon } from '@fortawesome/fontawesome-svg-core';

const drawerWidth = 300;

const Root = styled.div`
  display: flex;
  margin: auto;
  position: absolute;
  top: 50px;
  bottom: 0;
  left: 0;
  right: 0;
`;

interface StyledAppBarProps {
  open: boolean;
  theme: any;
}

const StyledAppBar = styled(AppBar)<StyledAppBarProps>`
  z-index: ${({ theme }) => theme.zIndex.drawer + 1};
  transition: ${({ theme }) =>
    theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    })};

  ${({ open, theme }) =>
    open &&
    css`
      margin-left: ${drawerWidth}px;
      width: calc(100% - ${drawerWidth}px);
      transition: ${theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      })};
    `}
`;

const MenuButton = styled(IconButton)`
  margin-right: ${({ theme }) => theme.spacing(2)}px;
`;

const Hide = styled.div`
  display: none;
`;

const StyledDrawer = styled(MuiDrawer)`
  width: ${drawerWidth}px;
  flex-shrink: 0;

  & .MuiDrawer-paper {
    width: ${drawerWidth}px;
    background: #f2f2f2;
  }
`;

const DrawerHeader = styled.div`
  display: flex;
  align-items: center;
  padding: ${({ theme }) => theme.spacing(0, 1)};
  ${({ theme }) => theme.mixins.toolbar}
  justify-content: space-between;
`;

interface ContentProps {
  open: boolean;
  theme: any;
  isHomePage?: boolean;
}

const Content = styled.main<ContentProps>`
  flex-grow: 1;
  padding: ${({ theme }) => theme.spacing(3)}px;
  transition: ${({ theme }) =>
    theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    })};
  margin-left: ${({ isHomePage }) => (isHomePage ? `0` : `-${drawerWidth}`)}px;
  background: #f2f2f2;

  ${({ open, theme }) =>
    open &&
    css`
      margin-left: 0;
      transition: ${theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      })};
    `}
`;

export default function MiniDrawer({ children }: { children: ReactNode }) {
  const theme = useTheme();
  const [open, setOpen] = useState(true);
  const { pathname } = useLocation();
  const isHomePage =
    pathname === '/' ||
    pathname === '/unauthorized' ||
    pathname === '/tokeninvalid' ||
    pathname === '/downpage';
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const { username } = useUserContext();
  const { instance } = useMsal();

  const handleMenuClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    setAnchorEl(null);
    appInsights.clearAuthenticatedUserContext();
    instance.logoutRedirect({
      postLogoutRedirectUri: '/',
    });
  };

  const handleToggleDrawer = () => {
    setOpen((o) => !o);
  };

  return (
    <Root>
      <CssBaseline />
      <StyledAppBar position="fixed" open={open} theme={theme}>
        <Toolbar sx={{ justifyContent: 'space-between' }}>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            {!isHomePage && (
              <MenuButton
                color="inherit"
                aria-label="open drawer"
                onClick={handleToggleDrawer}
                edge="start"
                theme={theme}
              >
                <MenuIcon />
              </MenuButton>
            )}
            <Typography
              variant="h6"
              component={RouterLink}
              to="/"
              style={{ textDecoration: 'none', color: 'inherit' }}
            >
              Skylight
            </Typography>
          </Box>
          <div>
            {/* <IconButton
              size="large"
              aria-label="show 17 new notifications"
              color="inherit"
            >
              <Badge badgeContent={0} color="error">
                <NotificationsIcon />
              </Badge>
            </IconButton> */}
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleMenuClick}
              color="inherit"
              disabled={true}
            >
              {/* <AccountCircle /> */}
              <FluentUIAvatar name={username} />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorEl)}
              onClose={handleMenuClose}
            >
              {/* <MenuItem onClick={handleMenuClose}>Settings</MenuItem>
              <MenuItem onClick={handleLogout}>Logout</MenuItem> */}
            </Menu>
          </div>
        </Toolbar>
      </StyledAppBar>
      {!isHomePage && (
        <StyledDrawer
          variant="persistent"
          anchor="left"
          open={open}
          theme={theme}
        >
          <DrawerHeader theme={theme}>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                columnGap: 1,
                marginLeft: 3,
              }}
            >
              <Avatar src="skylight.jpg" style={{ width: 24, height: 24 }} />
              <Typography
                variant="h6"
                component={RouterLink}
                to="/"
                style={{ textDecoration: 'none', color: 'inherit' }}
              >
                Skylight
              </Typography>
            </Box>
            <IconButton onClick={handleToggleDrawer}>
              {theme.direction === 'ltr' ? (
                <ChevronLeftIcon />
              ) : (
                <ChevronRightIcon />
              )}
            </IconButton>
          </DrawerHeader>
          <Divider />
          <List>
            {[
              {
                name: 'Home',
                url: '/',
                icon: <HomeIcon />,
              },
              {
                name: 'TSG Copilot (Preview)',
                url: `/tsg-copilot`,
                icon: <TSGCopilotIcon />,
              },
              {
                name: 'SME Assistant(Preview)',
                url: '/sme-assistant',
                icon: <SMEAssistantIcon />,
              },
            ].map(({ name, url, icon }, index) => (
              <ListItemButton
                key={url}
                selected={url === pathname}
                sx={{ display: 'block', padding: 0 }}
              >
                <NavLink to={url}>
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      minHeight: 48,
                      justifyContent: open ? 'flex-start' : 'center',
                      padding: '6px 16px',
                    }}
                  >
                    <ListItemIcon
                      sx={{
                        minWidth: 0,
                        mr: open ? 3 : 'auto',
                        justifyContent: 'center',
                      }}
                    >
                      {icon}
                    </ListItemIcon>
                    <ListItemText
                      primary={name}
                      sx={{
                        opacity: open ? 1 : 0,
                        overflowWrap: 'break-word',
                        wordWrap: 'break-word',
                        whiteSpace: 'normal',
                      }}
                    />
                  </Box>
                </NavLink>
              </ListItemButton>
            ))}
          </List>
          <Divider />
          <List>
            {[
              {
                name: 'RCA Copilot (Preview)',
                url: `/rca-copilot`,
                icon: (
                  <Avatar
                    src={KQLAssistantIcon}
                    style={{ width: 24, height: 24 }}
                  />
                ),
                disabled: false,
              },
              {
                name: 'Network Trace Analyzer(Preview)',
                url: '/network-trace-analyzer',
                icon: (
                  <Avatar
                    src={NetworkTraceAnalyzerIcon}
                    style={{ width: 24, height: 24 }}
                  />
                ),
                disabled: false,
              },
              // {
              //   name: 'KQL Assistant(Preview)',
              //   url: '/kql-assistant',
              //   icon: <Chat />,
              //   disabled: true,
              // },
            ].map(({ name, url, icon, disabled }, index) => (
              <ListItemButton
                key={url}
                selected={url === pathname}
                sx={{ display: 'block', padding: 0 }}
                disabled={!!disabled}
              >
                <NavLink to={url}>
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      minHeight: 48,
                      justifyContent: open ? 'flex-start' : 'center',
                      padding: '6px 16px',
                    }}
                  >
                    <ListItemIcon
                      sx={{
                        minWidth: 0,
                        mr: open ? 3 : 'auto',
                        justifyContent: 'center',
                      }}
                    >
                      {icon}
                    </ListItemIcon>
                    <ListItemText
                      primary={name}
                      sx={{
                        opacity: open ? 1 : 0,
                        overflowWrap: 'break-word',
                        wordWrap: 'break-word',
                        whiteSpace: 'normal',
                      }}
                    />
                  </Box>
                </NavLink>
              </ListItemButton>
            ))}
          </List>
        </StyledDrawer>
      )}
      <Content open={open && !isHomePage} isHomePage={isHomePage} theme={theme}>
        {children}
      </Content>
    </Root>
  );
}
