import React from 'react';
import { Stack, Spinner, Icon, Label } from '@fluentui/react';

const SpinningComponent = ({ message }: any) => {
  return (
    <Stack
      horizontal
      horizontalAlign="center"
      verticalAlign="center"
      tokens={{ childrenGap: 10 }}
      style={{ marginTop: '2rem' }}
    >
      <Icon iconName="info" />
      {message && <Label style={{ fontWeight: 400 }}>{message}</Label>}
      <Spinner />
    </Stack>
  );
};

export default SpinningComponent;
